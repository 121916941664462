<template>
  <div class="card flex">
    <BlockUI :blocked="blocked" :fullScreen="true">
      <FileUpload
        name="cerebro[]"
        accept="text/csv"
        customUpload
        @uploader="onCerebroUpload"
        :maxFileSize="1000000"
        :auto="true"
        :showUploadButton="false"
        :showCancelButton="false"
      >
        <template #empty>
          <p v-if="blocked">processing...</p>
          <p v-else>Drag and drop Cerebro CSV file to here to upload.</p>
        </template>
      </FileUpload>

      <div class="flex flex-column gap-2 p-2">
        <div v-if="result.error_message !== ''">
          Error Message: {{ result.error_message }}
        </div>

        <div v-if="result.file_name !== ''">
          Google Sheet:
          <a :href="result.file_link" target="_blank" rel="noreferrer noopener">
            {{ result.file_name }}
          </a>
        </div>
      </div>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import FileUpload from "primevue/fileupload";

import MkwCerebroService from "@/services/mkw_cerebro_service";

export default defineComponent({
  components: {
    BlockUI,
    FileUpload,
  },

  setup() {
    const toast = useToast();
    const blocked = ref(false);
    const result = reactive({
      error_message: "",
      file_name: "",
      file_link: "",
    });

    const onCerebroUpload = async (event) => {
      blocked.value = true;
      result.error_message = "";
      result.file_name = "";
      result.file_link = "";

      const file = event.files[0];
      const reader = new FileReader();

      reader.readAsText(file);

      // Load event
      reader.onload = () => {
        const csvdata = reader.result;
        const cerebro = csvdata.split("\n").map((row) => {
          return row.split(",").map((cell) => {
            return cell.replace(/^"|"$/g, ""); // remove double quote in head and tail
          });
        });

        MkwCerebroService.normalize(cerebro)
          .then(({ data }) => {
            result.error_message = "";
            result.file_name = data.file_name;
            result.file_link = data.file_link;

            toast.add({
              severity: "success",
              summary: "Normalize Successful!",
              life: 3000,
            });
          })
          .catch((error) => {
            if (error?.data?.error_message) {
              result.error_message = error.data.error_message;
            }

            toast.add({
              severity: "error",
              summary: "Normalize Fail!",
              life: 3000,
            });
          })
          .finally(() => {
            blocked.value = false;
          });
      };
    };

    return {
      blocked,
      result,

      onCerebroUpload,
    };
  },
});
</script>
