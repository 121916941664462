import { axiosApiInstance } from "@/services/base";

class MkwCerebroService {
  normalize(cerebro) {
    return axiosApiInstance.post("/mkw/cerebro/normalizes", {
      cerebro_data: [cerebro], // to fit chrome extension version
    });
  }
}

export default new MkwCerebroService();
